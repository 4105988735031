import Base = require("Everlaw/Base");
import Type = require("Everlaw/Type");
import Is = require("Everlaw/Core/Is");
import Document = require("Everlaw/Document"); // circular dependency - use for types only
import { Color } from "Everlaw/ColorUtil";
import Rest = require("Everlaw/Rest");
import ComboBox = require("Everlaw/UI/ComboBox");

import { SearchWithExactness } from "Everlaw/Type";

module Typed {
    export abstract class Value {
        value: any;
        abstract getField(): Field;
        display(): string {
            return this.getField().display() + ": " + this.displayValue();
        }
        displayValue(value?: any): string {
            return this.getField()
                .getType()
                .displayValue(Is.defined(value) ? value : this.value);
        }
        searchWithExactness(type: Type.FieldType): SearchWithExactness<unknown> | null {
            return this.getField().type.searchFromExactValue(this.value, type);
        }
        compare(other: Value) {
            return this.getField().compare(other.getField()) || this.value < other.value
                ? -1
                : this.value > other.value
                  ? 1
                  : 0;
        }
        equals(other: Value) {
            return this.getField().equals(other.getField()) && this.value === other.value;
        }
        equalsRawValue(other: any): boolean {
            return (
                !!other
                && !!this.value
                && this.getField().getType().compareRawValue(this.value, other)
            );
        }
    }

    export interface FieldParams extends Base.Secured {
        id: number;
        name: string;
        type: string;
    }

    export abstract class Field extends Base.SecuredObject implements Base.Colored {
        type: Type.FieldType;
        name: string;
        visible: boolean;
        constructor(params: FieldParams) {
            super(params);
            this._mixin(params);
        }
        override _mixin(params: FieldParams) {
            // if already in a name chain, remove
            Object.assign(this, params);
            this.type = Type.TYPE_BY_NAME[params.type];
        }
        getType() {
            return this.type;
        }
        displayValue(value?: any): string {
            return this.getType().displayValue(Is.defined(value) ? value : value);
        }
        getName() {
            return this.name;
        }
        override display() {
            return this.name;
        }
        abstract docValue(doc: Document): Value;
        abstract override get className(): string;
        abstract getColor(): Color;
        abstract getDatavisProperty(): string;
        abstract valueFromJson(json: string): Value;
    }

    /**
     * Returns ComboBox.Completions on success, or a string message on error.
     */
    export function autocomplete(
        val: string,
        reqParams: { params: any; url: string; method: "GET" | "POST" },
    ) {
        const req = reqParams.method === "GET" ? Rest.get : Rest.post;
        return req(reqParams.url, reqParams.params).catch((err: Rest.Failed) => err.message);
    }

    export function emptyAutocomplete() {
        return Promise.resolve<string | ComboBox.Completions>({
            matches: [],
            count: 0,
            exceeded: false,
        });
    }
}

export = Typed;
